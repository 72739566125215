import React, { useState, useEffect, useRef } from "react";
import "./Header.scss";
import { Link, useNavigate } from "react-router-dom";
import useCustomerAuth from "../../../hooks/useCustomerAuth";
import CartIcon from "../../../assets/images/img/cart-cus.svg";
import UserIcon from "../../../assets/images/img/user-cus.svg";
import LogoIcon from "../../../assets/images/img/logo.svg";
import InstaSvg from "../../../assets/images/img/insta-cus.svg";
import FbSvg from "../../../assets/images/img/fb-cus.svg";
import LogoutSVG from "../../../assets/images/img/logourSVG.svg";
import { CartState } from "../../../context/CustomerCart";
import { LuLogIn } from "react-icons/lu";

const Header = () => {
  const navigate = useNavigate();
  const {
    state: { cart },
  } = CartState();

  //console.log("Cart from header", cart);

  const { user, logout } = useCustomerAuth();
  const [avatarBgColor, setAvatarBgColor] = useState("#ccc");

  console.log("user: ", user);

  const menuRef = useRef(null);

  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  /*  useEffect(() => {
    Storage.set(config.customerCartItems, JSON.stringify(cart));
  }, [cart, config.customerCartItems]); */

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuActive(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Generate random background color for avatar
  useEffect(() => {
    if (user) {
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      setAvatarBgColor(randomColor);
    }
  }, [user]);

  return (
    <>
      <div className="top-header">
        <h6>Call Us : +91 9849022026</h6>
        {user ? (
          <h6> </h6>
        ) : (
          <>
            <h6>
              Sign up and GET 35% OFF for your first order.
              <Link to="/login">
                <span
                  style={{
                    color: "rgba(246, 247, 145, 1)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Sign up now
                </span>
              </Link>
            </h6>
          </>
        )}

        <div className="top-header-social">
          <img src={InstaSvg} alt="" />
          <img src={FbSvg} alt="" />
        </div>
      </div>

      <header className="main-header" id="main-header">
        <nav className="navbar container">
          <div className="logo">
            <img src={LogoIcon} alt="" />
            <h4 onClick={() => navigate("/")}>
              Hind
              <span style={{ color: "hsla(122, 60%, 21%, 0.95)" }}>wala</span>
              .com
              <span style={{ color: "#F6F791" }}>.</span>
            </h4>
          </div>

          <div
            className={`menu ${menuActive ? "is-active" : ""}`}
            id="menu"
            ref={menuRef}
          >
            <ul className="menu-inner">
              <li className="menu-item" onClick={closeMenu}>
                <Link to="/home" className="menu-link">
                  Home
                </Link>
              </li>

              <li className="menu-item" onClick={closeMenu}>
                <Link to="/categories" className="menu-link">
                  Categories
                </Link>
              </li>
              <li className="menu-item" onClick={closeMenu}>
                <Link to="/about" className="menu-link">
                  About Us
                </Link>
              </li>
              <li className="menu-item" onClick={closeMenu}>
                <Link to="/contact" className="menu-link">
                  Contact Us
                </Link>
              </li>
              <li className="menu-item" onClick={closeMenu}>
                <Link to="/order-details" className="menu-link">
                  My Orders
                </Link>
              </li>
            </ul>

            <div className={`headers-icon ${menuActive ? "is-active" : ""}`}>
              <div
                className={`${
                  menuActive ? "no-cart-icon" : "cart-icon-container"
                }`}
                onClick={() => {
                  navigate("/cart");
                  closeMenu();
                }}
              >
                <img
                  className={`${menuActive ? "img-active" : ""} `}
                  src={CartIcon}
                  alt=""
                />

                {cart && cart.length > 0 && (
                  <div className="cart-count">
                    <p>{cart?.length}</p>
                  </div>
                )}
              </div>

              {user ? (
                <>
                  <div
                    className={`user-profile ${
                      menuActive ? "mobile-profile" : ""
                    }`}
                    style={{
                      backgroundColor: avatarBgColor,
                      color: "#fff",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {user.credentialResponseDecoded.name.charAt(0)}
                  </div>
                  <img
                    src={LogoutSVG}
                    alt=""
                    style={{ width: "22px" }}
                    onClick={() => logout()}
                  />
                </>
              ) : (
                <>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    onClick={() => {
                      navigate("/login");
                      closeMenu();
                    }}
                  >
                    {" "}
                    <LuLogIn size={22} />
                    <p
                      style={{
                        fontSize: "12px",
                        fontFamily: "Montserrat-Medium",
                        marginBottom: "0",
                      }}
                    >
                      Login
                    </p>
                  </div>

                  <img
                    className={`${menuActive ? "  mobile-profile" : ""} `}
                    src={UserIcon}
                    alt=""
                  />
                </>
              )}
            </div>

            <div className={`mobile-logo ${menuActive ? "is-active" : ""}`}>
              <img src={LogoIcon} alt="" />
              <h6>Hindwala.com</h6>
            </div>
          </div>

          {/* Hamburger Menu */}
          <div className="mobile-hamburger-menu">
            <div
              className="cart-icon-container"
              onClick={() => {
                navigate("/cart");
                closeMenu();
              }}
            >
              <img
                className={`${menuActive ? "img-active" : ""} `}
                src={CartIcon}
                alt=""
              />

              {cart && cart.length > 0 && (
                <div className="cart-count">
                  <p>{cart.length}</p>
                </div>
              )}
            </div>
            <div
              className="burger"
              id="burger"
              onClick={toggleMenu}
              style={{ position: "relative" }}
            >
              <span
                className="burger-line"
                style={{
                  transform: menuActive
                    ? "rotate(45deg) translate(0.5rem, 0.22rem)"
                    : "none",
                  transition: "transform 0.3s ease",
                }}
              ></span>
              <span
                className="burger-line"
                style={{
                  opacity: menuActive ? 0 : 1,
                  transition: "opacity 0.3s ease",
                }}
              ></span>
              <span
                className="burger-line"
                style={{
                  transform: menuActive
                    ? "rotate(-45deg) translate(0.5rem, -0.22rem)"
                    : "none",
                  transition: "transform 0.3s ease",
                }}
              ></span>
            </div>
          </div>

          {menuActive && <div className="overlay" onClick={closeMenu}></div>}
        </nav>
      </header>
    </>
  );
};

export default Header;
