import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./MobileCarousel.scss";
import { FaCircleChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const MobileCarousel = ( {featuredProducts} ) => {

 
  const navigate = useNavigate();
  if (featuredProducts.length === 0) {
    return;
  }
  return (
    <div className="main-content">
      <Carousel
        autoPlay
        infiniteLoop
        showArrows
        showStatus={false}
        showThumbs={false}
        swipeable
        emulateTouch
        interval={2000}
        stopOnHover
      >
        {featuredProducts.map((product) => (
          <div
            key={product._id}
            className="slider-box"
            onClick={() => {
              navigate({
                pathname: "/products/product-details",
                search: `productId=${product?._id}`,
              });
            }}
          >
            <img src={product.images[0]} alt={product.name} />
            <p className="legend product-name">
              {product.name}{" "}
              <FaCircleChevronRight
                size={20}
                color="rgba(2, 95, 0, 1)"
                style={{ marginRight: ".3rem" }}
              />
            </p> 
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MobileCarousel;
