import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import SofaSVG from "../../../../assets/images/img/cus-sofa-small.svg";
import { FaArrowRight } from "react-icons/fa6";

import "./Home.scss";

import { FaCircleChevronRight } from "react-icons/fa6";
import { FaCircleChevronLeft } from "react-icons/fa6";
import ShippingSvg from "../../../../assets/images/img/cus-shiping.svg";
import PaymentSvg from "../../../../assets/images/img/cus-payment.svg";
import SupportSvg from "../../../../assets/images/img/cus-suport.svg";
import MobileCarousel from "../../../../assets/elements/Customer/MobileCarousel";

import Line from "../../../../assets/images/img/cus-line.svg";

import { deBounce } from "../../../../assets/functions/TimingFunctions";
import MainServices from "../../../../services/MainServices";
import { CustomerLazyLoader } from "../../../../assets/loaders";
import { GoSearch } from "react-icons/go";

//Tabs
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Swiper
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import SwiperCore from "swiper";

import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Product from "../../../../assets/elements/Customer/Product";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Home = () => {
  const navigate = useNavigate();

  const { getProducts, getAllCategories } = MainServices();

  const [productList, setProductList] = useState([]);
  const [categories, setCategories] = useState([]);

  const [sofasList, setSofasList] = useState([]);
  const [sofasListCount, setSofasListCount] = useState();

  const [chairsList, setChairsList] = useState([]);
  const [chairsListCount, setChairsListCount] = useState();

  const [bedList, setBedList] = useState([]);
  const [bedListCount, setBedListCount] = useState();

  const [categoryProducts, setCategoryProducts] = useState({
    All: [],
    Sofas: [],
    Chairs: [],
    Beds: [],
    Tables: [],
    DiningTables: [],
    Mattress: [],
    BeanBags: [],
    Wardrobes: [],
    TeaTables: [],
    Bedsheets: [],
    Curtains: [],
    Wallpapers: [],
  });

  const tabList = [
    "All",
    "Sofas",
    "Chairs",
    "Beds",
    "Tables",
    "DiningTables",
    "Mattress",
    "BeanBags",
    "Wardrobes",
    "TeaTables",
    "Bedsheets",
    "Curtains",
    "Wallpapers",
  ];

  const [cateogryLoading, setCategoryLoading] = useState(false);

  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  //get product list api
  const getProductsList = async () => {
    setPageLoading(true);
    const details = {};

    await axios
      .all([getProducts(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          //console.log(total);
          if (resData?.success) {
            resData = resData.data ? resData.data?.data : [];
            //console.log(resData);
            setProductList(resData);
            // Filter featured products
            const featured = resData?.filter(
              (product) => product.featured_product === true
            );
            setFeaturedProducts(featured);

            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        }
        setPageLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Products");
      });
  };

  const fetchCategoryProducts = async (category) => {
    setCategoryLoading(true);
    const details =
      category === "All"
        ? { productName: searchString }
        : { category, productName: searchString };

    try {
      const response = await getProducts(details);
      if (response.success) {
        const products = response.data?.data || [];
        setCategoryProducts((prev) => ({
          ...prev,
          [category]: products,
        }));
        setCategoryLoading(false);
      } else {
        setCategoryProducts((prev) => ({
          ...prev,
          [category]: [],
        }));
      }
    } catch (error) {
      console.error(
        `An error occurred in retrieving ${category} Products`,
        error
      );
      setCategoryProducts((prev) => ({
        ...prev,
        [category]: [],
      }));
    } finally {
      setCategoryLoading(false);
    }
  };

  const handleTabSelect = (category) => {
    if (categoryProducts[category].length === 0) {
      fetchCategoryProducts(category, searchString);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const getCategoriesList = async () => {
    const details = {};

    await axios
      .all([getAllCategories(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let total = resData?.data?.total;
          //console.log(total);
          if (resData.success) {
            resData = resData.data ? resData.data?.data : [];
            console.log(resData);
            setCategories(resData);
          } else {
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving categories");
      });
  };

  useEffect(() => {
    getProductsList();
    getCategoriesList();

    fetchCategoryProducts("All", searchString);
    // eslint-disable-next-line
  }, [searchString]);

  console.log("catgegory", categories);

  return (
    <>
      <div className="main-home">
        <div className="top-banner">
          <div className="left">
            <div className="best-div">
              <div className="sofa-div">
                <img src={SofaSVG} alt="" />
              </div>

              <h5>The Best E-Commerce Store</h5>
            </div>

            <div className="heading">
              <div className="left-heading">
                <h2>
                  Explore Our{" "}
                  <span style={{ color: "rgba(2, 109, 0, 1)" }}>
                    Modern <br /> Products Collection....
                  </span>
                </h2>
                <p>
                  Delivering Comfort as a Priority: Quick and Easy Access to a
                  Wide Variety of Products
                </p>
                <button
                  className="shop-btn"
                  onClick={() => navigate("/categories")}
                >
                  Shop Now <FaArrowRight />
                </button>
              </div>

              {/* Mobile swiper  */}
              {pageLoading ? (
                <div className="flex-center">
                  <CustomerLazyLoader />
                </div>
              ) : (
                <div className="mobile-featured-swiper">
                  <MobileCarousel featuredProducts={featuredProducts} />
                </div>
              )}

              {/* Swiper */}
              {pageLoading ? (
                <div className="flex-center destop-swiper-loader">
                  <CustomerLazyLoader />
                </div>
              ) : (
                <Swiper
                  ref={swiperRef}
                  slidesPerView={3}
                  centeredSlides={true}
                  spaceBetween={30}
                  grabCursor={true}
                  navigation={false}
                  pagination={false}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                    waitForTransition: true,
                    reverseDirection: false,
                    effect: "slide",
                    easing: "ease",
                  }}
                  loop={true}
                  className="mySwiper"
                  modules={[Pagination, Navigation]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    400: {
                      slidesPerView: 1,
                      centeredSlides: true,
                      spaceBetween: "0",
                      autoplay: false,
                    },
                    639: {
                      slidesPerView: 4,
                      centeredSlides: "true",
                    },
                    865: {
                      slidesPerView: 4,
                      centeredSlides: "true",
                    } /*
                1000: {
                  slidesPerView: 5,
                }, 
                1500: {
                  slidesPerView: 6,
                },
                1700: {
                  slidesPerView: 7,
                }, */,
                  }}
                >
                  {featuredProducts.map((slide) => (
                    <SwiperSlide key={slide._id}>
                      <div
                        className="slider-content"
                        onClick={() => {
                          navigate({
                            pathname: "/products/product-details",
                            search: `productId=${slide?._id}`,
                          });
                        }}
                      >
                        <div className="img-container">
                          <img src={slide.images[0]} alt="" />
                        </div>

                        <div className="slider-content-heading">
                          <div>
                            <h4>{slide.name}</h4>
                            <p>{slide?.price?.toLocaleString()}</p>
                          </div>

                          <FaCircleChevronRight
                            size={30}
                            color="rgba(2, 95, 0, 1)"
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}

              <div className="custom-swiper-arrows">
                <div className="swiper-button-prev" onClick={handlePrev}>
                  <FaCircleChevronLeft size={24} color="rgba(2, 95, 0, 1)" />
                </div>
                <div className="swiper-button-next" onClick={handleNext}>
                  <FaCircleChevronRight size={24} color="rgba(0, 0, 0, 1)" />
                </div>
              </div>
              {/* Swiper */}
            </div>

            {/* Ratings */}
            <div className="ratings-div">
              <div>
                <h5>250+</h5>
                <p>Collections</p>
              </div>
              <div>
                <h5>5K+</h5>
                <p>Customers</p>
              </div>
              <div>
                <h5>4.5+</h5>
                <p>Review's </p>
              </div>
            </div>

            {/* Delivery support */}
            <div className="delivery-support-container">
              <div className="delivery-content">
                <div className="del-img-container">
                  <img src={ShippingSvg} alt="" />
                </div>
                <div>
                  <h6>Shipping</h6>
                  <p>Free shipping for above 15,000 INR</p>
                </div>
              </div>
              <div className="delivery-content">
                <div className="del-img-container">
                  <img src={PaymentSvg} alt="" />
                </div>
                <div>
                  <h6>Flexible Payments</h6>
                  <p>Multiple secure payment options</p>
                </div>
              </div>
              <div className="delivery-content">
                <div className="del-img-container">
                  <img src={SupportSvg} alt="" />
                </div>
                <div>
                  <h6>24/7 Support</h6>
                  <p>We support online all days.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="colored-div"></div>
        </div>

        {/* Categories */}
        <div className="main-cat main-best-seller">
          <h6>
            <img src={Line} alt="" />
            Categories
          </h6>
          <div className="product-categories">
            {categories.map((cat) => (
              <div
                className="categories"
                onClick={() =>
                  navigate("/category-based-products", { state: cat })
                }
              >
                <div className="image-container">
                  <img src={cat.image} alt="" />
                </div>

                <h6>{cat.categoryName}</h6>
              </div>
            ))}
          </div>
        </div>

        {/* Best Seller */}

        <div className="main-best-seller">
          {productList && productList.length > 0 ? (
            <>
              <div className="best-seller-headings">
                <h6>
                  <img src={Line} alt="" />
                  Best Seller
                </h6>
                <div className="search-heading">
                  <h4>Our Best Seller Products</h4>

                  <div className="search-container">
                    <GoSearch className="search-icon" size={22} />
                    <input
                      type="text"
                      placeholder="Search Product name..."
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                <div className="tabs-products">
                  <>
                    {/*  {categoryProducts.slice(0, 9).map((product) => (
                      <Product product={product} key={product._id} />
                    ))} */}
                  </>

                  <Tabs onSelect={(index) => handleTabSelect(tabList[index])}>
                    {/*  <TabList>
                      {tabList.map((category) => (
                        <Tab key={category}>{category}</Tab>
                      ))}
                    </TabList> */}

                    {cateogryLoading ? (
                      <CustomerLazyLoader />
                    ) : (
                      <>
                        {" "}
                        {tabList.map((category) => (
                          <TabPanel key={category}>
                            <div className="product-container">
                              {categoryProducts[category].length === 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <h4 style={{ textAlign: "center" }}>
                                    Sorry! we don't have this product
                                    currently..
                                  </h4>
                                </div>
                              ) : (
                                <>
                                  {categoryProducts[category]
                                    .slice(0, 9)
                                    .map((product) => (
                                      <Product
                                        product={product}
                                        key={product._id}
                                      />
                                    ))}
                                </>
                              )}
                            </div>
                          </TabPanel>
                        ))}
                      </>
                    )}
                  </Tabs>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="shop-btn"
                  onClick={() => navigate("/categories")}
                >
                  View All Collections <FaArrowRight />
                </button>
              </div>
            </>
          ) : (
            <div>
              <h6>Sorry.. No Products Available</h6>
            </div>
          )}
        </div>

        {/* Discount Offer */}
        {/*  <div className="discount-offer-container">
          <div className="discount-div">
            <div className="left-content">
              <h5>Flat 50% Discount</h5>

              <h4>Latest Gaming Chairs</h4>

              <p>
                Lorem ipsum dolor sit amet consectetur. Lorem tempus fames quis
                pharetra pulvinar urna.
              </p>

              <button className="shop-now">Shop Now</button>
            </div>
            <div className="right-content">
              <img src={GamingChair} alt="" />
            </div>
          </div>

          <div className="discount-div">
            <div className="left-content">
              <h5>Flat 20% Discount</h5>

              <h4>Wood Chair Collection</h4>

              <p>
                Lorem ipsum dolor sit amet consectetur. Lorem tempus fames quis
                pharetra pulvinar urna.
              </p>

              <button className="shop-now">Shop Now</button>
            </div>
            <div className="right-content">
              <img src={WoodenChair} alt="" />
            </div>
          </div>
        </div>  */}

       
      </div>
    </>
  );
};

export default Home;
